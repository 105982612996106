import { useEffect, useState, useRef } from "react";
import classes from "./TradingBanner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/trade/1/m1.png";
import img2 from "../../../assets/img/trade/1/m2.png";
import img3 from "../../../assets/img/trade/1/m3.png";
import img4 from "../../../assets/img/trade/1/m4.png";
import img5 from "../../../assets/img/trade/1/globus.png";
import img6 from "../../../assets/img/trade/1/circle.png";
import { Trans, useTranslation } from "react-i18next";
import imgMob from "../../../assets/img/trade/1/mob.png";
import imgMob1 from "../../../assets/img/trade/1/mob1.png";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const TradingBanner = () => {
  const { t } = useTranslation("");
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const mainRef = useRef(null);

  const item1 = useRef(null);
  const item2 = useRef(null);
  const item3 = useRef(null);
  const item4 = useRef(null);
  const item5 = useRef(null);
  const item6 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(item1.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(item2.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(item3.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(item4.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(item5.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(item6.current, {
        y: 0,
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.banner, ""].join(" ")} ref={mainRef}>
      <div className={[classes.bannerBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.bannerWrapContent, ""].join(" ")}>
            <h1 className={[classes.bannerTitle, "font-64"].join(" ")}>
              <Trans>trading_1_1</Trans>
            </h1>
            <h2
              className={[classes.bannerSubtitle, "font-48-banner"].join(" ")}
            >
              <Trans>trading_1_2</Trans>
            </h2>
            <p className={[classes.bannerText, "font-20"].join(" ")}>
              <Trans>trading_1_3</Trans>
            </p>
            <p className={[classes.bannerText, "font-20"].join(" ")}>
              <Trans>trading_1_4</Trans>
            </p>
            <p className={[classes.bannerOverBtn, "font-24"].join(" ")}>
              <Trans>trading_1_5</Trans>
            </p>
            <div className={[classes.bannerBtn, ""].join(" ")}>
              <Button secondary={"true"}>
                <Trans>btn</Trans>
              </Button>
            </div>
            <div className={[classes.bannerImfgs, ""].join(" ")}>
              <div
                className={[classes.bannerImfgsImg1, ""].join(" ")}
                ref={item1}
              >
                <img src={img1} alt="IR Strategies" />
              </div>
              <div
                className={[classes.bannerImfgsImg2, ""].join(" ")}
                ref={item2}
              >
                <img src={img2} alt="IR Strategies" />
              </div>
              <div
                className={[classes.bannerImfgsImg3, ""].join(" ")}
                ref={item3}
              >
                <img src={img3} alt="IR Strategies" />
              </div>
              <div
                className={[classes.bannerImfgsImg4, ""].join(" ")}
                ref={item4}
              >
                <img src={img4} alt="IR Strategies" />
              </div>
              <div
                className={[classes.bannerImfgsImg5, ""].join(" ")}
                ref={item5}
              >
                <img src={img5} alt="IR Strategies" />
              </div>
              <div
                className={[classes.bannerImfgsImg6, ""].join(" ")}
                ref={item6}
              >
                <img src={img6} alt="IR Strategies" />
              </div>
            </div>
            {width < 1220 && (
              <div className={[classes.bannerMob, ""].join(" ")}>
                <img src={width > 480 ? imgMob : imgMob1} alt="IR Strategies" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingBanner;
