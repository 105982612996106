import React from "react";
import classes from "./Banner.module.scss";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image_01 from "../../../assets/img/SecurityPage/Banner/image_01.png";
import image_02 from "../../../assets/img/SecurityPage/Banner/image_02.png";
import circle from "../../../assets/img/SecurityPage/Banner/circle.png";
import { useEffect } from "react";
import { useRef } from "react";
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Banner = () => {
  const { t } = useTranslation();
  const imageRef_01 = useRef(null);
  const imageRef_02 = useRef(null);
  const contentRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        contentRef.current,
        {
          y: 150,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        imageRef_01.current,
        {
          y: 100,
          x: 60,
          autoAlpha: 0,
          scale: 0.8,
        },
        {
          y: 0,
          x: 0,
          autoAlpha: 1,
          scale: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        imageRef_02.current,
        {
          y: 80,
          x: -40,
          autoAlpha: 0,
          scale: 0.8,
        },
        {
          y: 0,
          x: 0,
          autoAlpha: 1,
          scale: 1,
          delay: 0.5,
          duration: 1,
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div className={classes.banner}>
        <div ref={contentRef} className={classes.content}>
          <h1 className={[classes.title, "font-48-banner"].join(" ")}>
            <Trans>security_1_1</Trans>
          </h1>
          <p className={[classes.text, "font-20"].join(" ")}>
            <Trans>security_1_2</Trans>
          </p>
          <div className={classes.btn}>
            <Button secondary>
              <Trans>open_btn</Trans>
            </Button>
          </div>
        </div>
        <img
          ref={imageRef_01}
          className={classes.image_01}
          src={image_01}
          alt=""
        />
        <img
          ref={imageRef_02}
          className={classes.image_02}
          src={image_02}
          alt=""
        />
        <img className={classes.circle} src={circle} alt="" />
      </div>
    </section>
  );
};

export default Banner;
