import React from "react";
import classes from './WhatTypes.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        title: 'BRONZE',
        price: '$ 1.000',
        list: [
            'what_types_01_col_01',
            'what_types_01_col_02',
            'what_types_01_col_03',
            'what_types_01_col_04',
            'what_types_01_col_05',
        ],
    },
    {
        title: 'SILVER',
        price: '$ 2.500',
        list: [
            'what_types_02_col_01',
            'what_types_02_col_02',
            'what_types_02_col_03',
            'what_types_02_col_04',
            'what_types_02_col_05',
        ],
    },
    {
        title: 'GOLD',
        price: '$ 10.000',
        list: [
            'what_types_03_col_01',
            'what_types_03_col_02',
            'what_types_03_col_03',
            'what_types_03_col_04',
            'what_types_03_col_05',
        ],
    },
    {
        title: 'PLATINUM',
        price: '$ 20.000',
        list: [
            'what_types_04_col_01',
            'what_types_04_col_02',
            'what_types_04_col_03',
            'what_types_04_col_04',
            'what_types_04_col_05',
            'what_types_04_col_06',
            'what_types_04_col_07',
        ],
    },
    {
        title: 'VIP',
        price: '$ 50.000',
        list: [
            'what_types_05_col_01',
            'what_types_05_col_02',
            'what_types_05_col_03',
            'what_types_05_col_04',
            'what_types_05_col_05',
            'what_types_05_col_06',
            'what_types_05_col_07',
            'what_types_05_col_08',
        ],
    },
];

const WhatTypes = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whatTypes}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('commissions_4_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <div className={[classes.cardHeader, 'font-24'].join(' ')}>
                                <h4 className={classes.cardTitle}>
                                    {card.title}
                                </h4>
                                <p className={classes.cardPrice}>
                                    {card.price}
                                </p>
                            </div>
                            <ul className="font-20">
                                {card.list.map((li, liIndex) =>
                                    <li key={liIndex}>
                                        {t(li)}
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhatTypes;
