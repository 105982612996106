import React, { useState } from "react";
import classes from "./SeeWhy.module.scss";
import { Trans, useTranslation } from "react-i18next";
import icon_01 from "../../../assets/img/HomePage/SeeWhy/icon_01.png";
import icon_02 from "../../../assets/img/HomePage/SeeWhy/icon_02.png";
import icon_03 from "../../../assets/img/HomePage/SeeWhy/icon_03.png";
import icon_04 from "../../../assets/img/HomePage/SeeWhy/icon_04.png";
import arrow from "../../../assets/img/HomePage/SeeWhy/arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { useEffect } from "react";
import { useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const cards = [
  {
    icon: icon_01,
    title: "main_7_item_11",
    text: "main_7_item_12",
  },
  {
    icon: icon_02,
    title: "main_7_item_21",
    text: "main_7_item_22",
  },
  {
    icon: icon_03,
    title: "main_7_item_31",
    text: "main_7_item_32",
  },
  {
    icon: icon_04,
    title: "main_7_item_41",
    text: "main_7_item_42",
  },
];

const SeeWhy = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const cardsRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.fromTo(
          cardsRef.current,
          {
            x: 500,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: cardsRef.current,
              end: "bottom bottom",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="mt container">
      <div className={classes.seeWhy}>
        <h2 className={[classes.title, "font-48"].join(" ")}>
          <Trans>main_7_1</Trans>
        </h2>
        <p className={[classes.text, "font-20"].join(" ")}>
          <Trans>main_7_2</Trans>
        </p>
        <div ref={cardsRef} className={classes.swiperWrap}>
          <Swiper
            modules={[Navigation]}
            className="homeSeeWhySwiper no-select"
            spaceBetween={20}
            slidesPerView={1}
            grabCursor
            pagination={{ clickable: true }}
            onInit={(e) => {
              setSwiper(e);
            }}
            breakpoints={{
              700: {
                slidesPerView: 2,
              },
              1220: {
                slidesPerView: 3,
              },
            }}
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index} className="homeSeeWhySwiperSlide">
                <div className={classes.card}>
                  <div className={classes.cardHeader}>
                    <img className={classes.cardIcon} src={card.icon} alt="" />
                    <p className={classes.cardTitle}>
                      <Trans>{card.title}</Trans>
                    </p>
                  </div>
                  <p className={classes.cardText}>
                    <Trans>{card.text}</Trans>
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={classes.navigation}>
            <img
              onClick={() => swiper.slidePrev()}
              className={[classes.nav, classes.nav_left].join(" ")}
              src={arrow}
              alt=""
            />
            <img
              onClick={() => swiper.slideNext()}
              className={[classes.nav, classes.nav_right].join(" ")}
              src={arrow}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeeWhy;
