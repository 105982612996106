import HomePage from '../pages/HomePage/HomePage';
import TradingPage from '../pages/TradingPage/TradingPage';
import SecurityPage from '../pages/SecurityPage/SecurityPage';
import AccountTypesPage from '../pages/AccountTypesPage/AccountTypesPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/trading', element: TradingPage },
    { path: '/security', element: SecurityPage },
    { path: '/account-types', element: AccountTypesPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/security', element: SecurityPage },
    { path: '/:lang/account-types', element: AccountTypesPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'trading', text: 'header_item1' },
    { id: 2, path: 'security', text: 'header_item2' },
    { id: 3, path: 'account-types', text: 'header_item3' },
    { id: 4, path: 'faq', text: 'header_item4' },
    { id: 5, path: 'about', text: 'header_item5' },
];
