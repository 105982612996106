import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import TradingBanner from "../../components/TradingComponents/TradingBanner/TradingBanner";
import WhatMarkets from "../../components/TradingComponents/WhatMarkets/WhatMarkets";
import TradingHours from "../../components/TradingComponents/TradingHours/TradingHours";
import LeverageInformation from "../../components/TradingComponents/LeverageInformation/LeverageInformation";
import HoldingCosts from "../../components/TradingComponents/HoldingCosts/HoldingCosts";

const TradingPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>IR Strategies · Trading</title>
        <meta
          name="title"
          content="IR Strategies Trading Platform - Easy, Reliable, and Secure"
        />
        <meta
          property="og:title"
          content="IR Strategies Trading Platform - Easy, Reliable, and Secure"
        />
        <meta
          property="twitter:title"
          content="IR Strategies Trading Platform - Easy, Reliable, and Secure"
        />

        <meta
          name="description"
          content="IR Strategies provides a powerful and intuitive trading platform for traders of all levels. Access real-time market data, analysis, and a wide range of trading tools to help you make informed decisions."
        />
        <meta
          property="og:description"
          content="IR Strategies provides a powerful and intuitive trading platform for traders of all levels. Access real-time market data, analysis, and a wide range of trading tools to help you make informed decisions."
        />
        <meta
          property="twitter:description"
          content="IR Strategies provides a powerful and intuitive trading platform for traders of all levels. Access real-time market data, analysis, and a wide range of trading tools to help you make informed decisions."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <TradingBanner />
        <TradingHours />
        <LeverageInformation />
        {/* <HoldingCosts /> */}
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingPage;
