import React, { useEffect, useRef } from "react";
import classes from "./PlatformGlance.module.scss";
import { Trans, useTranslation } from "react-i18next";
import image from "../../../assets/img/HomePage/PlatformGlance/image.jpg";
import circle from "../../../assets/img/HomePage/PlatformGlance/circle.jpg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const list = [
  "main_3_2",
  "main_3_3",
  "main_3_4",
  "main_3_5",
  "main_3_6",
  "main_3_7",
];

const PlatformGlance = () => {
  const { t } = useTranslation();
  const bodyRef = useRef(null);
  const imageRef = useRef(null);
  const circleRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          imageRef.current,
          {
            y: 200,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "top+=100px bottom",
              end: "+=400px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          circleRef.current,
          {
            x: 200,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              end: "+=400px",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="container">
      <div className="section-bg">
        <div ref={bodyRef} className={classes.platformGlance}>
          <img ref={imageRef} className={classes.image} src={image} alt="" />
          <div className={classes.content}>
            <h2 className={[classes.title, "font-48"].join(" ")}>
              <Trans>main_3_1</Trans>
            </h2>
            <ul className="font-20">
              {list.map((li, index) => (
                <li key={index}>
                  <Trans>{li}</Trans>
                </li>
              ))}
            </ul>
          </div>
          <img ref={circleRef} className={classes.circle} src={circle} alt="" />
        </div>
      </div>
    </section>
  );
};

export default PlatformGlance;
