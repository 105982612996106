import React, { useEffect, useRef } from "react";
import classes from "./Additional.module.scss";
import { Trans, useTranslation } from "react-i18next";
import icon_01 from "../../../assets/img/SecurityPage/Additional/icon_01.png";
import icon_02 from "../../../assets/img/SecurityPage/Additional/icon_02.png";
import icon_03 from "../../../assets/img/SecurityPage/Additional/icon_03.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Additional = () => {
  const { t } = useTranslation();
  const cardRef_01 = useRef(null);
  const cardRef_02 = useRef(null);
  const bodyRef = useRef(null);
  const cards = [
    {
      ref: cardRef_01,
      icon: icon_01,
      title: "security_2_2",
      text: "security_2_3",
    },
    {
      ref: cardRef_02,
      icon: icon_02,
      title: "security_2_4",
      text: "security_2_5",
    },
  ];
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          cardRef_01.current,
          {
            y: 100,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "center bottom",
              end: "+=300px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_02.current,
          {
            y: 300,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "center bottom",
              end: "+=300px",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="mt container">
      <div className={classes.additional}>
        <div ref={bodyRef} className={classes.cards}>
          <div className={classes.card}>
            <h2 className={[classes.title, "font-48"].join(" ")}>
              <Trans>security_2_1</Trans>
            </h2>
          </div>
          {cards.map((card, index) => (
            <div ref={card.ref} key={index} className={classes.card}>
              <img className={classes.cardIcon} src={card.icon} alt="" />
              <h4 className={[classes.cardTitle, "font-24"].join(" ")}>
                <Trans>{card.title}</Trans>
              </h4>
              <p className={[classes.cardText, "font-20"].join(" ")}>
                <Trans>{card.text}</Trans>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Additional;
