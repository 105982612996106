import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/FaqPage/Banner/Banner";
import GotQuestions from "../../components/FaqPage/GotQuestions/GotQuestions";

const FaqPage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>IR Strategies · FAQ & Support</title>
                <meta name="title" content="IR Strategies Support - Your Questions Answered" />
                <meta property="og:title" content="IR Strategies Support - Your Questions Answered" />
                <meta property="twitter:title" content="IR Strategies Support - Your Questions Answered" />
                
                <meta name="description" content="Get the answers you need with IR Strategies's comprehensive FAQ section. Our support team is also available to assist you with any questions or concerns you may have."/>
                <meta property="og:description" content="Get the answers you need with IR Strategies's comprehensive FAQ section. Our support team is also available to assist you with any questions or concerns you may have."/>
                <meta property="twitter:description" content="Get the answers you need with IR Strategies's comprehensive FAQ section. Our support team is also available to assist you with any questions or concerns you may have." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <GotQuestions />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default FaqPage;
