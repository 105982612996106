import React, { useEffect, useRef } from "react";
import classes from "./AccountProtection.module.scss";
import { Trans, useTranslation } from "react-i18next";
import image from "../../../assets/img/SecurityPage/AccountProtection/image.png";
import circle from "../../../assets/img/SecurityPage/AccountProtection/circle.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const AccountProtection = () => {
  const { t } = useTranslation();
  const bodyRef = useRef(null);
  const imageRef = useRef(null);
  const circleRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          imageRef.current,
          {
            y: 200,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "top+=100px bottom",
              end: "+=400px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          circleRef.current,
          {
            x: 150,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              end: "+=400px",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="mt container">
      <div className="section-bg">
        <div ref={bodyRef} className={classes.accountProtection}>
          <div className={classes.content}>
            <h2 className={[classes.title, "font-48"].join(" ")}>
              <Trans>security_3_1</Trans>
            </h2>
            <p className={[classes.text, "font-20"].join(" ")}>
              <Trans>security_3_2</Trans>
            </p>
          </div>
          <img ref={imageRef} className={classes.image} src={image} alt="" />
          <img ref={circleRef} className={classes.circle} src={circle} alt="" />
        </div>
      </div>
    </section>
  );
};

export default AccountProtection;
