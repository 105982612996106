import React, { useEffect, useRef } from "react";
import classes from "./OurTeam.module.scss";
import { Trans, useTranslation } from "react-i18next";
import image from "../../../assets/img/AboutPage/our_team.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const OurTeam = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        imageRef.current,
        {
          y: 200,
        },
        {
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div className="section-bg">
        <div className={classes.banner}>
          <div className={classes.content}>
            <h1 className={[classes.title, "font-48-banner"].join(" ")}>
              <Trans>about_2_1</Trans>
            </h1>
            <div className={[classes.text, "font-20"].join(" ")}>
              <p>
                <Trans>about_2_2</Trans>
              </p>
            </div>
          </div>
          <img ref={imageRef} className={classes.image} src={image} alt="" />
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
