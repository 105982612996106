import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import { Trans, useTranslation } from "react-i18next";

import map_left from "../../../assets/img/HomePage/Banner/map_left.png";
import map_right from "../../../assets/img/HomePage/Banner/map_right.png";
import plane_01 from "../../../assets/img/HomePage/Banner/plane_01.png";
import plane_02 from "../../../assets/img/HomePage/Banner/plane_02.png";
import plane_03 from "../../../assets/img/HomePage/Banner/plane_03.png";
import coin_01 from "../../../assets/img/HomePage/Banner/coin_01.png";
import coin_02 from "../../../assets/img/HomePage/Banner/coin_02.png";
import coin_03 from "../../../assets/img/HomePage/Banner/coin_03.png";
import coin_04 from "../../../assets/img/HomePage/Banner/coin_04.png";
import coin_05 from "../../../assets/img/HomePage/Banner/coin_05.png";
import coin_06 from "../../../assets/img/HomePage/Banner/coin_06.png";
import coin_07 from "../../../assets/img/HomePage/Banner/coin_07.png";
import bg_mob from "../../../assets/img/HomePage/Banner/bg_mob.png";
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Banner = () => {
  const { t } = useTranslation();

  const bodyRef = useRef(null);
  const contentRef = useRef(null);

  const mapLeftRef_container = useRef(null);
  const mapLeftRef_wrap = useRef(null);
  const mapRightRef_container = useRef(null);
  const mapRightRef_wrap = useRef(null);

  const planeRef_01__container = useRef(null);
  const planeRef_01__wrap = useRef(null);
  const planeRef_02__container = useRef(null);
  const planeRef_02__wrap = useRef(null);
  const planeRef_03__container = useRef(null);
  const planeRef_03__wrap = useRef(null);

  const coinRef_01__container = useRef(null);
  const coinRef_01__wrap = useRef(null);
  const coinRef_02__container = useRef(null);
  const coinRef_02__wrap = useRef(null);
  const coinRef_03__container = useRef(null);
  const coinRef_03__wrap = useRef(null);
  const coinRef_04__container = useRef(null);
  const coinRef_04__wrap = useRef(null);
  const coinRef_05__container = useRef(null);
  const coinRef_05__wrap = useRef(null);
  const coinRef_06__container = useRef(null);
  const coinRef_06__wrap = useRef(null);
  const coinRef_07__container = useRef(null);
  const coinRef_07__wrap = useRef(null);

  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      // START

      gsap.fromTo(
        mapLeftRef_container.current,
        {
          scale: 1.2,
          x: -100,
          autoAlpha: 0,
        },
        {
          scale: 1,
          x: 0,
          autoAlpha: 1,
          duration: 2,
        }
      );
      gsap.fromTo(
        mapRightRef_container.current,
        {
          scale: 1.2,
          x: 100,
          autoAlpha: 0,
        },
        {
          scale: 1,
          x: 0,
          autoAlpha: 1,
          duration: 2,
        }
      );
      gsap.fromTo(
        contentRef.current,
        {
          scale: 0,
          autoAlpha: 0,
        },
        {
          scale: 1,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        planeRef_01__container.current,
        {
          scale: 0.8,
          x: -200,
          y: -40,
          rotate: 20,
          autoAlpha: 0,
        },
        {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1.5,
        }
      );
      gsap.fromTo(
        planeRef_02__container.current,
        {
          scale: 0.8,
          x: 180,
          y: -200,
          rotate: -10,
          autoAlpha: 0,
        },
        {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 0.6,
        }
      );
      gsap.fromTo(
        planeRef_03__container.current,
        {
          scale: 0.8,
          x: 160,
          y: 140,
          rotate: -5,
          autoAlpha: 0,
        },
        {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 0.8,
        }
      );

      gsap.fromTo(
        coinRef_01__container.current,
        {
          y: 140,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_02__container.current,
        {
          y: 110,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_03__container.current,
        {
          y: 100,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_04__container.current,
        {
          y: 80,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_05__container.current,
        {
          y: 140,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_06__container.current,
        {
          y: 190,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        coinRef_07__container.current,
        {
          y: 160,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 1,
          duration: 1,
        }
      );

      gsap.timeline().to(mapLeftRef_wrap.current, {
        scale: 1.1,
        x: -100,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(mapRightRef_wrap.current, {
        scale: 1.1,
        x: 100,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });

      gsap.timeline().to(planeRef_01__wrap.current, {
        scale: 0.8,
        x: 80,
        y: 50,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(planeRef_02__wrap.current, {
        scale: 0.8,
        x: -90,
        y: 120,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(planeRef_03__wrap.current, {
        scale: 0.8,
        x: -120,
        y: -130,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });

      gsap.timeline().to(coinRef_01__wrap.current, {
        y: 100,
        x: 10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_02__wrap.current, {
        y: 200,
        x: 10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_03__wrap.current, {
        y: 100,
        x: 10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_04__wrap.current, {
        y: 150,
        x: 10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_05__wrap.current, {
        y: 270,
        x: -10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_06__wrap.current, {
        y: 150,
        x: -10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
      gsap.timeline().to(coinRef_07__wrap.current, {
        y: 140,
        x: -10,
        scrollTrigger: {
          start: "+=200px",
          end: "+=1200px",
          scrub: 3,
        },
      });
    }
  }, []);
  return (
    <section ref={bodyRef} className={classes.bannerWrap}>
      <div ref={mapLeftRef_container} className={classes.mapLeft__container}>
        <div ref={mapLeftRef_wrap} className={classes.mapLeft__wrap}>
          <img className={classes.mapLeft} src={map_left} alt="" />
        </div>
      </div>
      <div ref={mapRightRef_container} className={classes.mapRight__container}>
        <div ref={mapRightRef_wrap} className={classes.mapRight__wrap}>
          <img className={classes.mapRight} src={map_right} alt="" />
        </div>
      </div>
      <img className={classes.bgMob} src={bg_mob} alt="" />
      <div className="container">
        <div className={classes.banner}>
          <div ref={contentRef} className={classes.content}>
            <h1 className={classes.title}>
              <span className="font-54">
                <Trans>main_1_1</Trans>
              </span>
              <span className="subtitle-banner">
                <Trans>main_1_2</Trans>
              </span>
            </h1>
            <p className={[classes.text, "font-24"].join(" ")}>
              <Trans>main_1_3</Trans>
            </p>
            <div className={classes.btn}>
              <Button>
                <Trans>btn</Trans>
              </Button>
            </div>
          </div>
          {/* PLANES */}
          <div ref={planeRef_01__container} className={[classes.plane_01__container, classes.planeContainer].join(" ")}>
            <div ref={planeRef_01__wrap} className={classes.planeWrap}>
              <img className={classes.plane} src={plane_01} alt="" />
            </div>
          </div>
          <div ref={planeRef_02__container} className={[classes.plane_02__container, classes.planeContainer].join(" ")}>
            <div ref={planeRef_02__wrap} className={classes.planeWrap}>
              <img className={classes.plane} src={plane_02} alt="" />
            </div>
          </div>
          <div ref={planeRef_03__container} className={[classes.plane_03__container, classes.planeContainer].join(" ")}>
            <div ref={planeRef_03__wrap} className={classes.planeWrap}>
              <img className={classes.plane} src={plane_03} alt="" />
            </div>
          </div>
          {/* COINS */}
          <div ref={coinRef_01__container} className={[classes.coin_01, classes.coinContainer].join(" ")}>
            <div ref={coinRef_01__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_01} alt="" />
            </div>
          </div>
          <div ref={coinRef_02__container} className={[classes.coin_02, classes.coinContainer].join(" ")}>
            <div ref={coinRef_02__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_02} alt="" />
            </div>
          </div>
          <div ref={coinRef_03__container} className={[classes.coin_03, classes.coinContainer].join(" ")}>
            <div ref={coinRef_03__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_03} alt="" />
            </div>
          </div>
          <div ref={coinRef_04__container} className={[classes.coin_04, classes.coinContainer].join(" ")}>
            <div ref={coinRef_04__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_04} alt="" />
            </div>
          </div>
          <div ref={coinRef_05__container} className={[classes.coin_05, classes.coinContainer].join(" ")}>
            <div ref={coinRef_05__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_05} alt="" />
            </div>
          </div>
          <div ref={coinRef_06__container} className={[classes.coin_06, classes.coinContainer].join(" ")}>
            <div ref={coinRef_06__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_06} alt="" />
            </div>
          </div>
          <div ref={coinRef_07__container} className={[classes.coin_07, classes.coinContainer].join(" ")}>
            <div ref={coinRef_07__wrap} className={classes.coinWrap}>
              <img className={classes.coin} src={coin_07} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
