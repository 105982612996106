import logo from '../assets/img/logo.png';
import img1 from '../assets/img/AboutPage/banner.png';
import img2 from '../assets/img/AccountTypesPage/Banner/arrow.png';
import img3 from '../assets/img/AccountTypesPage/Banner/circle.png';
import img4 from '../assets/img/AccountTypesPage/Banner/globe.png';
import img5 from '../assets/img/AccountTypesPage/Banner/rocket.png';
import img6 from '../assets/img/AccountTypesPage/Banner/woman.png';
import img7 from '../assets/img/FaqPage/woman.png';
import img8 from '../assets/img/HomePage/Banner/coin_01.png';
import img9 from '../assets/img/HomePage/Banner/bg_mob.png';
import img10 from '../assets/img/HomePage/Banner/coin_02.png';
import img11 from '../assets/img/HomePage/Banner/coin_03.png';
import img12 from '../assets/img/HomePage/Banner/coin_04.png';
import img13 from '../assets/img/HomePage/Banner/coin_05.png';
import img14 from '../assets/img/HomePage/Banner/coin_06.png';
import img15 from '../assets/img/HomePage/Banner/coin_07.png';
import img16 from '../assets/img/HomePage/Banner/map_left.png';
import img17 from '../assets/img/HomePage/Banner/map_right.png';
import img18 from '../assets/img/HomePage/Banner/plane_01.png';
import img19 from '../assets/img/HomePage/Banner/plane_02.png';
import img20 from '../assets/img/HomePage/Banner/plane_03.png';
import img21 from '../assets/img/SecurityPage/Banner/circle.png';
import img22 from '../assets/img/SecurityPage/Banner/image_01.png';
import img23 from '../assets/img/SecurityPage/Banner/image_02.png';
import img24 from '../assets/img/trade/1/circle.png';
import img25 from '../assets/img/trade/1/globus.png';
import img26 from '../assets/img/trade/1/m1.png';
import img27 from '../assets/img/trade/1/m2.png';
import img28 from '../assets/img/trade/1/m3.png';
import img29 from '../assets/img/trade/1/m4.png';
import img30 from '../assets/img/trade/1/mob.png';
import img31 from '../assets/img/trade/1/mob1.png';

export const images = [
    logo,
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31
];
