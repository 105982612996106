import React, { useState } from "react";
import classes from "./GotQuestions.module.scss";
import { Trans, useTranslation } from "react-i18next";

const tabs = [
  {
    title: "faq_2_item11",
    text: "faq_2_item12",
  },
  {
    title: "faq_2_item21",
    text: "faq_2_item22",
  },
  {
    title: "faq_2_item31",
    text: "faq_2_item32",
  },
  {
    title: "faq_2_item41",
    text: "faq_2_item42",
  },
];

const GotQuestions = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const setTab = (index) => {
    if (index === current) {
      setCurrent(-1);
      return;
    }
    setCurrent(index);
  };
  return (
    <section className="mt container">
      <div className={classes.gotQuestions}>
        <h2 className={[classes.title, "font-48"].join(" ")}>
          <Trans>faq_2_1</Trans>
        </h2>
        <h3 className={[classes.subtitle, "font-40"].join(" ")}>
          <Trans>faq_2_2</Trans>
        </h3>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              className={`${classes.tab} ${
                current === index && classes.tabActive
              } no-select`}
              key={index}
              onClick={() => setTab(index)}
            >
              <div className={classes.tabHeader}>
                <h4 className={[classes.tabTitle, "font-24"].join(" ")}>
                  <Trans>{tab.title}</Trans>
                </h4>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className={[classes.tabText, "font-20"].join(" ")}>
                  <Trans>{tab.text}</Trans>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GotQuestions;
