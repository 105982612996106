import React, { useState } from "react";
import classes from "./LeverageInformation.module.scss";
import { Trans, useTranslation } from "react-i18next";
const LeverageInformation = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: <Trans>trading_4_item1_title</Trans>,
      active: true,
      component: ForexTab,
    },
    {
      id: 3,
      title: <Trans>trading_4_item2_title</Trans>,
      active: false,
      component: BondsTab,
    },
    {
      id: 4,
      title: <Trans>trading_4_item3_title</Trans>,
      active: false,
      component: ETFsTab,
    },
    {
      id: 5,
      title: <Trans>trading_4_item4_title</Trans>,
      active: false,
      component: CommoditiesTab,
    },
  ]);
  return (
    <div className={[classes.hours, "mt"].join(" ")}>
      <div className={[classes.hoursBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.hoursTitle, "font-48-banner"].join(" ")}>
            <Trans>trading_4_1</Trans>
          </h2>

          <div className={[classes.hoursTabs, ""].join(" ")}>
            {tabs.map((i) => {
              return (
                <div
                  className={[classes.hoursTab, ""].join(" ")}
                  onClick={() => {
                    setTabs(
                      tabs.map((subI) => {
                        if (subI.id === i.id) {
                          return { ...subI, active: true };
                        } else {
                          return { ...subI, active: false };
                        }
                      })
                    );
                  }}
                >
                  <div className={[classes.hoursTabTop, ""].join(" ")}>
                    <p
                      className={[classes.hoursTabTopLeft, "font-32"].join(" ")}
                    >
                      {i.title}
                    </p>
                    <div
                      className={
                        i.active
                          ? [classes.hoursTabTopRight, classes.active, ""].join(
                              " "
                            )
                          : [classes.hoursTabTopRight, ""].join(" ")
                      }
                    >
                      <div
                        className={[classes.hoursTabTopRightCircle, ""].join(
                          " "
                        )}
                      >
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className={[classes.hoursTabContent, ""].join(" ")}>
                    {i.active && <i.component />}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ForexTab = () => {
  return (
    <div className={[classes.forex, ""].join(" ")}>
      <div className={[classes.forexTable, ""].join(" ")}>
        <div className={[classes.forexTop, ""].join(" ")}>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item1_top1</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item1_top2</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item1_top3</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item1_top4</Trans>
          </p>
        </div>

        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURUSD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>AUDJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>GBPUSD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>NZDUSD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>CHFJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>USDJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURAUD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>AUDUSD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>CADJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>USDCHF</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>GBPAUD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>GBPJPY</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURCAD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>USDCAD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>AUDCAD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURGBP</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>GBPCAD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>EURCHF</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>AUDNZD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:150</p>
        </div>
      </div>
    </div>
  );
};

const BondsTab = () => {
  return (
    <div className={[classes.bonds, ""].join(" ")}>
      <div className={[classes.bondsTable, ""].join(" ")}>
        <div className={[classes.bondsTableTop, ""].join(" ")}>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item2_top1</Trans>
          </p>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item2_top2</Trans>
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            All Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:100
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            All Stocks
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:150
          </p>
        </div>
      </div>
    </div>
  );
};
const ETFsTab = () => {
  return (
    <div className={[classes.bonds, classes.etfs, ""].join(" ")}>
      <div className={[classes.bondsTable, ""].join(" ")}>
        <div className={[classes.bondsTableTop, ""].join(" ")}>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item3_top1</Trans>
          </p>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item3_top2</Trans>
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Equity ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:500
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Fixed Income ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:500
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Commodity ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:500
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Currency ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:500
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Leveraged ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:150
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Inverse ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            1:150
          </p>
        </div>
      </div>
    </div>
  );
};
const CommoditiesTab = () => {
  return (
    <div className={[classes.forex, classes.commodities1, ""].join(" ")}>
      <div className={[classes.forexTable, ""].join(" ")}>
        <div className={[classes.forexTop, ""].join(" ")}>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item4_top1</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item4_top2</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item4_top3</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_4_item4_top4</Trans>
          </p>
        </div>

        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Gold</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Sugar</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Silver</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Cocoa</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Oil WTI</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Corn</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Oil Brent
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Soybean</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Natural Gas
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Wheat</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Platinum
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Palladium
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Coffee</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>XAUEUR</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:100</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Cotton</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>1:50</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}></p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}></p>
        </div>
      </div>
    </div>
  );
};

export default LeverageInformation;
