import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AboutPage/Banner/Banner";
import OurValues from "../../components/AboutPage/OurValues/OurValues";
import OurTeam from "../../components/AboutPage/OurTeam/OurTeam";

const AboutPage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>IR Strategies · About Us</title>
                <meta name="title" content="About IR Strategies - Your Partner in Trading Success" />
                <meta property="og:title" content="About IR Strategies - Your Partner in Trading Success" />
                <meta property="twitter:title" content="About IR Strategies - Your Partner in Trading Success" />
                
                <meta name="description" content="Learn more about IR Strategies, our team, and our commitment to providing a seamless and intuitive trading experience. Join our community of traders and start your journey towards financial success."/>
                <meta property="og:description" content="Learn more about IR Strategies, our team, and our commitment to providing a seamless and intuitive trading experience. Join our community of traders and start your journey towards financial success."/>
                <meta property="twitter:description" content="Learn more about IR Strategies, our team, and our commitment to providing a seamless and intuitive trading experience. Join our community of traders and start your journey towards financial success." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <OurTeam />
                <OurValues />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default AboutPage;
