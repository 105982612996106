import React, { useState } from "react";
import classes from "./TradingHours.module.scss";
import WhatMarkets from "../WhatMarkets/WhatMarkets";
import { Trans, useTranslation } from "react-i18next";
const TradingHours = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: <Trans>trading_3_item1_title</Trans>,
      active: true,
      component: ForexTab,
    },
    {
      id: 2,
      title: <Trans>trading_3_item2_title</Trans>,
      active: false,
      component: StocksTab,
    },
    {
      id: 3,
      title: <Trans>trading_3_item3_title</Trans>,
      active: false,
      component: BondsTab,
    },
    {
      id: 4,
      title: <Trans>trading_3_item4_title</Trans>,
      active: false,
      component: ETFsTab,
    },
    {
      id: 5,
      title: <Trans>trading_3_item5_title</Trans>,
      active: false,
      component: CommoditiesTab,
    },
  ]);
  return (
    <div>
      <WhatMarkets tabs={tabs} setTabs={setTabs} />
      <div className={[classes.hours, "mt"].join(" ")}>
        <div className={[classes.hoursBody, ""].join(" ")}>
          <div className="container">
            <h2 className={[classes.hoursTitle, "font-48-banner"].join(" ")}>
              <Trans>trading_3_1</Trans>
            </h2>
            <p className={[classes.hoursSubtitle, "font-20"].join(" ")}>
              <Trans>trading_3_2</Trans>
            </p>

            <div className={[classes.hoursTabs, ""].join(" ")}>
              {tabs.map((i) => {
                return (
                  <div
                    id={"tab" + i.id}
                    className={[classes.hoursTab, ""].join(" ")}
                    onClick={() => {
                      setTabs(
                        tabs.map((subI) => {
                          if (subI.id === i.id) {
                            return { ...subI, active: true };
                          } else {
                            return { ...subI, active: false };
                          }
                        })
                      );
                    }}
                  >
                    <div className={[classes.hoursTabTop, ""].join(" ")}>
                      <p
                        className={[classes.hoursTabTopLeft, "font-32"].join(
                          " "
                        )}
                      >
                        {i.title}
                      </p>
                      <div
                        className={
                          i.active
                            ? [
                                classes.hoursTabTopRight,
                                classes.active,
                                "",
                              ].join(" ")
                            : [classes.hoursTabTopRight, ""].join(" ")
                        }
                      >
                        <div
                          className={[classes.hoursTabTopRightCircle, ""].join(
                            " "
                          )}
                        >
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div className={[classes.hoursTabContent, ""].join(" ")}>
                      {i.active && <i.component />}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ForexTab = () => {
  return (
    <div className={[classes.forex, ""].join(" ")}>
      <div className={[classes.forexTable, ""].join(" ")}>
        <div className={[classes.forexTop, ""].join(" ")}>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item1_top1</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item1_top2</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item1_top3</Trans>
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>All</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 22:05-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>-</p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>USD/EUR</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Monday 08:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-08:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>USD/GBP</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Monday 08:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-08:00
          </p>
        </div>
      </div>
    </div>
  );
};

const StocksTab = () => {
  return (
    <div className={[classes.stocks, ""].join(" ")}>
      <div className={[classes.stocksTable, ""].join(" ")}>
        <div className={[classes.stocksTableTop, ""].join(" ")}>
          <p className={[classes.stocksTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item2_top1</Trans>
          </p>
          <p className={[classes.stocksTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item2_top2</Trans>
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Apple Inc. (AAPL)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Microsoft Corporation (MSFT)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Amazon.com Inc. (AMZN)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Alphabet Inc. (GOOGL)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Facebook Inc. (FB)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            JPMorgan Chase & Co. (JPM)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.stocksTableRow, ""].join(" ")}>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Exxon Mobil Corporation (XOM)
          </p>
          <p className={[classes.stocksTableRowColumn, "font-20"].join(" ")}>
            Monday to Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
      </div>
    </div>
  );
};
const BondsTab = () => {
  return (
    <div className={[classes.bonds, ""].join(" ")}>
      <div className={[classes.bondsTable, ""].join(" ")}>
        <div className={[classes.bondsTableTop, ""].join(" ")}>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item3_top1</Trans>
          </p>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item3_top2</Trans>
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            US Treasury Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Corporate Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Municipal Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            International Government Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Varies by market
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            High Yield Bonds
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Mortgage-Backed Securities
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Asset-Backed Securities
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 8:00 AM - 5:00 PM ET
          </p>
        </div>
      </div>
    </div>
  );
};
const ETFsTab = () => {
  return (
    <div className={[classes.bonds, classes.efts, ""].join(" ")}>
      <div className={[classes.bondsTable, ""].join(" ")}>
        <div className={[classes.bondsTableTop, ""].join(" ")}>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item4_top1</Trans>
          </p>
          <p className={[classes.bondsTableTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item4_top2</Trans>
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Equity ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Fixed Income ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Commodity ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Currency ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Leveraged ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
        <div className={[classes.bondsTableRow, ""].join(" ")}>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Inverse ETFs
          </p>
          <p className={[classes.bondsTableRowColumn, "font-20"].join(" ")}>
            Monday - Friday, 9:30 AM - 4:00 PM ET
          </p>
        </div>
      </div>
    </div>
  );
};
const CommoditiesTab = () => {
  return (
    <div className={[classes.forex, classes.commodities, ""].join(" ")}>
      <div className={[classes.forexTable, ""].join(" ")}>
        <div className={[classes.forexTop, ""].join(" ")}>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item5_top1</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item5_top2</Trans>
          </p>
          <p className={[classes.forexTopItem, "font-20"].join(" ")}>
            <Trans>trading_3_item5_top3</Trans>
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Gold</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Silver</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>Oil WTI</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Oil BRENT
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Monday 01:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-01:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Natural Gas
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Platinum
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Palladium
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>XAUEUR</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
        <div className={[classes.forexRow, ""].join(" ")}>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>XAUAUD</p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            Sunday 23:00-Friday 22:00
          </p>
          <p className={[classes.forexRowItem, "font-20"].join(" ")}>
            22:00-23:00
          </p>
        </div>
      </div>
    </div>
  );
};

export default TradingHours;
