import React, { useEffect, useRef } from "react";
import classes from "./Advantages.module.scss";
import { Trans, useTranslation } from "react-i18next";
import icon_01 from "../../../assets/img/HomePage/Advantages/icon_01.png";
import icon_02 from "../../../assets/img/HomePage/Advantages/icon_02.png";
import icon_03 from "../../../assets/img/HomePage/Advantages/icon_03.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Advantages = () => {
  const { t } = useTranslation();
  const cardRef_01 = useRef(null);
  const cardRef_02 = useRef(null);
  const cardRef_03 = useRef(null);
  const bodyRef = useRef(null);
  const cards = [
    {
      ref: cardRef_01,
      icon: icon_01,
      title: "main_6_4",
      text: "main_6_5",
    },
    {
      ref: cardRef_02,
      icon: icon_02,
      title: "main_6_6",
      text: "main_6_7",
    },
    {
      ref: cardRef_03,
      icon: icon_03,
      title: "main_6_8",
      text: "main_6_9",
    },
  ];
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          cardRef_01.current,
          {
            y: 100,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "center bottom",
              end: "+=300px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_02.current,
          {
            y: 300,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "center bottom",
              end: "+=300px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_03.current,
          {
            y: 500,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "center bottom",
              end: "+=300px",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="mt container">
      <div className={classes.advantages}>
        <h2 className={[classes.title, "font-48"].join(" ")}>
          <Trans>main_6_1</Trans>
        </h2>
        <h4 className={[classes.subtitle, "font-24"].join(" ")}>
          <Trans>main_6_2</Trans>
        </h4>
        <p className={[classes.text, "font-20"].join(" ")}>
          <Trans>main_6_3</Trans>
        </p>
        <div ref={bodyRef} className={classes.cards}>
          {cards.map((card, index) => (
            <div ref={card.ref} className={classes.card} key={index}>
              <img className={classes.cardIcon} src={card.icon} alt="" />
              <h4 className={[classes.cardTitle, "font-24"].join(" ")}>
                <Trans>{card.title}</Trans>
              </h4>
              <p className={[classes.cardText, "font-20"].join(" ")}>
                <Trans>{card.text}</Trans>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Advantages;
