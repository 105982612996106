import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import { Trans, useTranslation } from "react-i18next";
import woman from "../../../assets/img/AccountTypesPage/Banner/woman.png";
import circle from "../../../assets/img/AccountTypesPage/Banner/circle.png";
import rocket from "../../../assets/img/AccountTypesPage/Banner/rocket.png";
import globe from "../../../assets/img/AccountTypesPage/Banner/globe.png";
import arrow from "../../../assets/img/AccountTypesPage/Banner/arrow.png";
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Banner = () => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const womanRef = useRef(null);
  const circleRef = useRef(null);
  const rocketRef = useRef(null);
  const globeRef = useRef(null);
  const arrowRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        circleRef.current,
        {
          y: 100,
        },
        {
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        contentRef.current,
        {
          y: 150,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        womanRef.current,
        {
          y: 40,
          scale: 0.95,
          autoAlpha: 0,
        },
        {
          y: 0,
          scale: 1,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        globeRef.current,
        {
          y: -50,
          x: 80,
          scale: 0.9,
          autoAlpha: 0,
        },
        {
          y: 0,
          x: 0,
          scale: 1,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        rocketRef.current,
        {
          y: 80,
          x: -30,
          autoAlpha: 0,
        },
        {
          y: 0,
          x: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        arrowRef.current,
        {
          x: -30,
          autoAlpha: 0,
          rotate: 20,
        },
        {
          x: 0,
          autoAlpha: 1,
          rotate: 0,
          delay: 0.5,
          duration: 1,
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div className={classes.banner}>
        <div ref={contentRef} className={classes.content}>
          <h2 className={[classes.title, "font-40"].join(" ")}>
            <Trans>commissions_1_1</Trans>
          </h2>
          <h1 className={[classes.subtitle, "font-48-banner"].join(" ")}>
            <Trans>commissions_1_2</Trans>
          </h1>
          <p className={[classes.text, "font-20"].join(" ")}>
            <Trans>commissions_1_3</Trans>
          </p>
          <div className={classes.btn}>
            <Button secondary>
              <Trans>btn</Trans>
            </Button>
          </div>
        </div>
        <img ref={womanRef} className={classes.woman} src={woman} alt="" />
        <img ref={globeRef} className={classes.globe} src={globe} alt="" />
        <img ref={circleRef} className={classes.circle} src={circle} alt="" />
        <img ref={rocketRef} className={classes.rocket} src={rocket} alt="" />
        <img ref={arrowRef} className={classes.arrow} src={arrow} alt="" />
      </div>
    </section>
  );
};

export default Banner;
