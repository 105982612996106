import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/SecurityPage/Banner/Banner";
import Additional from "../../components/SecurityPage/Additional/Additional";
import AccountProtection from "../../components/SecurityPage/AccountProtection/AccountProtection";
import ForYou from "../../components/HomePage/ForYou/ForYou";
import BalanceProtection from "../../components/SecurityPage/BalanceProtection/BalanceProtection";

const SecurityPage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>IR Strategies · Security</title>
                <meta name="title" content="Security Measures at IR Strategies - Your Funds are Safe with Us" />
                <meta property="og:title" content="Security Measures at IR Strategies - Your Funds are Safe with Us" />
                <meta property="twitter:title" content="Security Measures at IR Strategies - Your Funds are Safe with Us" />
                
                <meta name="description" content="At IR Strategies, we take your security seriously. We use state-of-the-art technology and strict security measures to protect your funds and personal information."/>
                <meta property="og:description" content="At IR Strategies, we take your security seriously. We use state-of-the-art technology and strict security measures to protect your funds and personal information."/>
                <meta property="twitter:description" content="At IR Strategies, we take your security seriously. We use state-of-the-art technology and strict security measures to protect your funds and personal information." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <Additional />
                <AccountProtection />
                <BalanceProtection />
                <ForYou />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default SecurityPage;
