import React, { useEffect, useRef } from "react";
import classes from "./DepWith.module.scss";
import { Trans, useTranslation } from "react-i18next";

import image from "../../../assets/img/HomePage/dep_with.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const DepWith = () => {
  const { t } = useTranslation();
  const bodyRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().fromTo(
          imageRef.current,
          {
            y: 200,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: bodyRef.current,
              start: "top+=100px bottom",
              end: "+=400px",
              scrub: 3,
            },
          }
        );
      }, 200);
    }
  }, []);
  return (
    <section className="mt container">
      <div className="section-bg">
        <div ref={bodyRef} className={classes.underBanner}>
          <div className={classes.content}>
            <h2 className={[classes.title, "font-48"].join(" ")}>
              <Trans>dep_with_title</Trans>
            </h2>
            <div className={[classes.text, "font-20"].join(" ")}>
              <p>
                <Trans>dep_with_text_01</Trans>
              </p>
              <p>
                <Trans>dep_with_text_02</Trans>
              </p>
            </div>
          </div>
          <img ref={imageRef} className={classes.image} src={image} alt="" />
        </div>
      </div>
    </section>
  );
};

export default DepWith;
