import React from "react";
import classes from "./WhatMarkets.module.scss";
import img1 from "../../../assets/img/trade/2/1.png";
import img2 from "../../../assets/img/trade/2/2.png";
import img3 from "../../../assets/img/trade/2/3.png";
import img4 from "../../../assets/img/trade/2/4.png";
import img5 from "../../../assets/img/trade/2/5.png";
import $ from "jquery";
import { Trans, useTranslation } from "react-i18next";
const WhatMarkets = (props) => {
  const { t } = useTranslation();
  function handleClick() {
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#mainForm").offset().top,
        },
        2000
      );
    }, 200);
  }
  return (
    <div className={[classes.what, "mt"].join(" ")}>
      <div className={[classes.whatBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.whatTitle, "font-48-banner"].join(" ")}>
            <Trans>trading_2_1</Trans>
          </h2>
          <div className={[classes.whatRow, ""].join(" ")}>
            <div
              className={[classes.whatRowColumn, ""].join(" ")}
              onClick={() => {
                setTimeout(() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#tab2").offset().top,
                    },
                    2000
                  );
                }, 200);
                props.setTabs(
                  props.tabs.map((i) => {
                    if (i.id === 2) {
                      return { ...i, active: true };
                    } else {
                      return { ...i, active: false };
                    }
                  })
                );
              }}
            >
              <div className={[classes.whatRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="What markets can I trade?" />
              </div>
              <p className={[classes.whatRowColumnText, "font-24"].join(" ")}>
                <Trans>trading_2_2</Trans>
              </p>
            </div>
            <div
              className={[classes.whatRowColumn, ""].join(" ")}
              onClick={() => {
                setTimeout(() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#tab1").offset().top,
                    },
                    2000
                  );
                }, 200);
                props.setTabs(
                  props.tabs.map((i) => {
                    if (i.id === 1) {
                      return { ...i, active: true };
                    } else {
                      return { ...i, active: false };
                    }
                  })
                );
              }}
            >
              <div className={[classes.whatRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="What markets can I trade?" />
              </div>
              <p className={[classes.whatRowColumnText, "font-24"].join(" ")}>
                <Trans>trading_2_3</Trans>
              </p>
            </div>
            <div
              className={[classes.whatRowColumn, ""].join(" ")}
              onClick={() => {
                setTimeout(() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#tab4").offset().top,
                    },
                    2000
                  );
                }, 200);
                props.setTabs(
                  props.tabs.map((i) => {
                    if (i.id === 4) {
                      return { ...i, active: true };
                    } else {
                      return { ...i, active: false };
                    }
                  })
                );
              }}
            >
              <div className={[classes.whatRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="What markets can I trade?" />
              </div>
              <p className={[classes.whatRowColumnText, "font-24"].join(" ")}>
                <Trans>trading_2_4</Trans>
              </p>
            </div>
            <div
              className={[classes.whatRowColumn, ""].join(" ")}
              onClick={() => {
                setTimeout(() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#tab3").offset().top,
                    },
                    2000
                  );
                }, 200);
                props.setTabs(
                  props.tabs.map((i) => {
                    if (i.id === 3) {
                      return { ...i, active: true };
                    } else {
                      return { ...i, active: false };
                    }
                  })
                );
              }}
            >
              <div className={[classes.whatRowColumnImg, ""].join(" ")}>
                <img src={img4} alt="What markets can I trade?" />
              </div>
              <p className={[classes.whatRowColumnText, "font-24"].join(" ")}>
                <Trans>trading_2_5</Trans>
              </p>
            </div>
            <div
              className={[classes.whatRowColumn, ""].join(" ")}
              onClick={() => {
                setTimeout(() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#tab5").offset().top,
                    },
                    2000
                  );
                }, 200);
                props.setTabs(
                  props.tabs.map((i) => {
                    if (i.id === 5) {
                      return { ...i, active: true };
                    } else {
                      return { ...i, active: false };
                    }
                  })
                );
              }}
            >
              <div className={[classes.whatRowColumnImg, ""].join(" ")}>
                <img src={img5} alt="What markets can I trade?" />
              </div>
              <p className={[classes.whatRowColumnText, "font-24"].join(" ")}>
                <Trans>trading_2_6</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMarkets;
