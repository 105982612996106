import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import { Trans, useTranslation } from "react-i18next";
import image from "../../../assets/img/AboutPage/banner.png";
import circle from "../../../assets/img/AboutPage/circle.png";
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Banner = () => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        contentRef.current,
        {
          y: 150,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        imageRef.current,
        {
          y: 70,
          x: 40,
          autoAlpha: 0,
          scale: 0.8,
        },
        {
          y: 0,
          x: 0,
          autoAlpha: 1,
          scale: 1,
          delay: 0.5,
          duration: 1,
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div className="section-bg">
        <div className={classes.banner}>
          <div ref={contentRef} className={classes.content}>
            <h1 className={[classes.title, "font-48-banner"].join(" ")}>
              <Trans>about_1_1</Trans>
            </h1>
            <h2 className={[classes.subtitle, "font-40"].join(" ")}>
              <Trans>about_1_2</Trans>
            </h2>
            <div className={[classes.text, "font-20"].join(" ")}>
              <p>
                <Trans>about_1_3</Trans>
              </p>
              <p>
                <Trans>about_1_4</Trans>
              </p>
            </div>
          </div>
          <img ref={imageRef} className={classes.image} src={image} alt="" />
          <img className={classes.circle} src={circle} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
