import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AccountTypesPage/Banner/Banner";
import Commissions from "../../components/AccountTypesPage/Commissions/Commissions";
import AccountTypes from "../../components/AccountTypesPage/AccountTypes/AccountTypes";
import WhatTypes from "../../components/AccountTypesPage/WhatTypes/WhatTypes";

const AccountTypesPage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>IR Strategies · Commissions & Account Types</title>
                <meta name="title" content="IR Strategies Commissions and Account Types - Choose the Right Option for You" />
                <meta property="og:title" content="IR Strategies Commissions and Account Types - Choose the Right Option for You" />
                <meta property="twitter:title" content="IR Strategies Commissions and Account Types - Choose the Right Option for You" />
                
                <meta name="description" content="Find the perfect trading account for your needs with IR Strategies. Explore our commission structures and account types to choose the best option for your trading style."/>
                <meta property="og:description" content="Find the perfect trading account for your needs with IR Strategies. Explore our commission structures and account types to choose the best option for your trading style."/>
                <meta property="twitter:description" content="Find the perfect trading account for your needs with IR Strategies. Explore our commission structures and account types to choose the best option for your trading style." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                {/* <Commissions /> */}
                {/* <AccountTypes /> */}
                <WhatTypes />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default AccountTypesPage;
