import React from "react";
import classes from './FastConv.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/FastConv/image_01.png';
import image_02 from '../../../assets/img/HomePage/FastConv/image_02.png';
import image_03 from '../../../assets/img/HomePage/FastConv/image_03.png';
import image_04 from '../../../assets/img/HomePage/FastConv/image_04.png';
import image_05 from '../../../assets/img/HomePage/FastConv/image_05.png';
import image_06 from '../../../assets/img/HomePage/FastConv/image_06.png';
import image_07 from '../../../assets/img/HomePage/FastConv/image_07.png';

const images = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
    image_06,
    image_07,
];

const FastConv = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.fastConv}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('fast_conv_title')}
                </h2>
                <div className={classes.cards}>
                    {images.map((image, index) =>
                        <img className={classes.image} src={image} key={index} />
                    )}
                </div>
            </div>
        </section>
    );
};

export default FastConv;
