import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import { Trans, useTranslation } from "react-i18next";
import circle from "../../../assets/img/SecurityPage/Banner/circle.png";
import woman from "../../../assets/img/FaqPage/woman.png";
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Banner = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const contentRef = useRef(null);
  const hostname = document.location.hostname;
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.fromTo(
        contentRef.current,
        {
          y: 150,
          autoAlpha: 0,
        },
        {
          y: 0,
          delay: 0.5,
          autoAlpha: 1,
          duration: 1,
        }
      );
      gsap.fromTo(
        imageRef.current,
        {
          y: 50,
          x: 50,
          autoAlpha: 0,
          scale: 0.9,
        },
        {
          y: 0,
          x: 0,
          delay: 0.5,
          scale: 1,
          autoAlpha: 1,
          duration: 1,
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div className={classes.banner}>
        <div ref={contentRef} className={classes.content}>
          <h1 className={[classes.title, "font-48-banner"].join(" ")}>
            <Trans>faq_1_1</Trans>
          </h1>
          <h2 className={[classes.subtitle, "font-40"].join(" ")}>
            <Trans>faq_1_2</Trans>
          </h2>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p>
              <Trans>faq_1_3</Trans>
            </p>
            <p>
              <Trans>faq_1_4</Trans>
            </p>
          </div>
          <h2 className={[classes.descTitle, "font-40"].join(" ")}>
            <Trans>faq_1_5</Trans>
          </h2>
          <div className={[classes.descText, "font-20"].join(" ")}>
            <p>
              <Trans>faq_1_6</Trans>
            </p>
            <p>
              <Trans>faq_1_7</Trans>
            </p>
            <p>
              <Trans>faq_1_8</Trans> support@{hostname}
            </p>
          </div>
        </div>
        <img ref={imageRef} className={classes.woman} src={woman} alt="" />
        <img className={classes.circle} src={circle} alt="" />
      </div>
      <div className={classes.mobDesc}>
        <h2 className={[classes.descTitle, "font-40"].join(" ")}>
          <Trans>faq_1_5</Trans>
        </h2>
        <div className={[classes.descText, "font-20"].join(" ")}>
          <p>
            <Trans>faq_1_6</Trans>
          </p>
          <p>
            <Trans>faq_1_7</Trans>
          </p>
          <p>
            <Trans>faq_1_8</Trans> support@{hostname}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
