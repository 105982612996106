import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const hostname = document.location.hostname;
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.grid}>
          <div className={classes.logo}>
            <img src={logo} alt="" />
          </div>
          <div className={classes.contacts}>
            <p>
              <b>
                <Trans>footer_contact_1</Trans>
              </b>
            </p>
            <p>
              Ransom Hall Ransom Wood Business Park, Ransom Wood, Nottinghamshire, England, NG21 0HJ
            </p>

            <div>
              Company number 06884783
            </div>
            <a href="tel:+442475200497">
              +442475200497
            </a>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
          </div>
          <div className={classes.docs}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              <Trans>footer_terms_1</Trans>
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              <Trans>footer_terms_2</Trans>
            </a>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              <Trans>footer_terms_3</Trans>
            </a>
          </div>
          <div></div>
          <div className={classes.disclosures_01}>
            {/* <div className={classes.title}>
              <Trans>footer_important_1</Trans>
            </div>
            <p className={classes.text}>
              <Trans>footer_important_2</Trans>
            </p> */}
            <div className={classes.title}>
              <Trans>footer_regional_title</Trans>
            </div>
            <p className={classes.text}>
              <Trans>footer_regional_text</Trans>
            </p>
          </div>
          {/* <div className={classes.disclosures_02}>
            <div className={classes.text}>
              <Trans>footer_right_1</Trans>
            </div>
          </div> */}
        </div>
        <div className={classes.rights}>
          <Trans>footer_copy</Trans>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
